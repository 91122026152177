import axios from 'axios'
import { useEffect, useState } from 'react'

import type {
  CollectionsApiResponse,
  ClusterItem,
} from '../local_modules/types/Cluster'

export const useAllCollections = () => {
  const [collections, setCollections] = useState<ClusterItem[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const request = async () => {
      try {
        const { data }: { data: CollectionsApiResponse } =
          await axios.post('/api/collections')

        const avaiableCollections =
          data?.data?.collections?.items
            ?.filter((collection) => collection.status === 'active')
            ?.reverse()
            ?.splice(0, 6) ?? []

        setCollections(avaiableCollections)
      } catch {
        throw new Error('Fail in all collections request')
      } finally {
        setLoading(false)
      }
    }

    request()
  }, [])

  return { collections, loading }
}
